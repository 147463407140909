<!--  -->
<template>
    <div class='ex-com-result-mask flex-center ' :class="{'bg-disappear': disappear}" v-if="active">
        <div class="com-result-body">
            <div class="re-item icon bounceIn"  :class="{'img-disappear': disappear}">
                <slot name="icon"></slot>
				<div class="icon-box " :class="resultCul" v-if="resultCul && !$slots['icon']"></div>
            </div>
            <div class="re-item extend" :class="{'disappear': disappear}">
                <slot name="extend"></slot>
                <div class="extend-box" v-if="resultCul && !$slots['extend']">
                    <div class="count-down-box text-center fadeInUp">
                        <van-count-down :time="time" format="ss" :auto-start="false" ref="countDown" @finish="finish" @change="change">
                            <template v-slot:default="data">
								<!-- 这里是一个小字部分 -->
                                <span class="text-small text-color ">{{data.seconds}}S后自动跳转</span>
                            </template>
                        </van-count-down>
                    </div>
                    <div class="extend-fun fadeIn">
                        <slot name="extend-fun"></slot>
                        <dia-button type="confirm" shape="square" class="flex-center" @click="next" v-if="!$slots['extend-fun']">
                            <span class="text-normal-a">{{TEXT.components.t15}}</span>
                        </dia-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            result: {
				type: String,
				default: 'right'
			},
            time: {
                type: Number,
                default: 5 * 1000,
            }
        },
        data() {
            return {
                active: true,
                resultVal: null,
				disappear:false,
            };
        },
        methods: {
            startCountDown(){
                if(!this.$refs['countDown']) return
                this.$refs['countDown'].start()
            },
            resetCountDown(){
                if(!this.$refs['countDown']) return
                this.$refs['countDown'].reset()
            },
            pauseCountDown(){
                if(!this.$refs['countDown']) return
                this.$refs['countDown'].pause()
            },
            paramsInit(opt){
                this.resultVal = null
                let {result} = opt || {}
                if(result) this.resultVal = result
            },
            async open(opt){
                this.paramsInit(opt)
                this.active = true
                await this._common.nextTick.call(this)
                this.resetCountDown()
                await this._common.settimeout(1000)
                this.startCountDown()
            },
            close(){
                this.pauseCountDown()
                this.active = false
            },
            finish(){
                this.$emit('finish')
            },
            change(){
                this.$emit('change')
            },
            next(){
				// 这里要加消失效果
				this.disappear = true
                this.$emit('next')
            },
			
        },
        created() {

        },
        components: {
        },
        computed: {
			
            resultCul(){
                if(this.resultVal) return this.resultVal
                return this.result
            },
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .ex-com-result-mask{
		/* 背景部分 */
        position: absolute;
        right: 0px;
        top: -@app-padding-head;
        width: calc(100% -  @board-size - @board-options-margin);
        height: calc(100% + @app-padding-head * 2);
        background: rgba(0, 0, 0, 0.65);
        .com-result-body{
            width: 100%;
            .icon-box{
                width: 100%;
                height: 100px;
            }

            .icon-box.right{
                background: url('@{assetsUrl}/img/subject/s3.png') no-repeat;
                background-size: 100% auto;
            }
            .icon-box.wrong{
                background: url('@{assetsUrl}/img/subject/s4.png') no-repeat;
                background-size: 100% auto;
            }
            .re-item.extend{
                margin-top: 8px;
            }
			/* 按钮部分 */
            .extend-fun{
                margin-top: 4px;
                .com-dia-button{
                    margin: 0px auto;
                }
            }
        }
		
    }
	@keyframes bounceIn {
		from,
		20%,
		40%,
		60%,
		80%,
		to {
			animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		}

		0% {
			opacity: 0;
			transform: scale3d(0.3, 0.3, 0.3);
		}

		50% {
			transform: scale3d(1.1, 1.1, 1.1);
		}

		80% {
			transform: scale3d(0.95, 0.95, 0.95);
		}

		to {
			opacity: 1;
			transform: scale3d(1, 1, 1);
		}
	}

	.bounceIn {
		animation-duration: 0.5s;
		animation-name: bounceIn;
	}
	@keyframes fadeInUp {
		from {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}
		50% {
			opacity: 0;
			transform: translate3d(0, 100%, 0);
		}

		to {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}

	.fadeInUp {
		animation-duration: 0.8s;
		animation-name: fadeInUp;
	}
	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		50% {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	.fadeIn {
		animation-duration: 0.8s;
		animation-name: fadeIn;
	}
	.disappear{
		animation-duration: 0.3s;
		animation-name: fadeOut;
	}
	@keyframes fadeOut {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
		
	}
	.bg-disappear{
		animation-duration: 0.4s;
		animation-name: bg-disappear;
	}
	@keyframes bg-disappear {
		0% {
			background: rgba(0, 0, 0, 0.65);
		}
		100% {
			background: rgba(0, 0, 0, 0);
		}
		
	}
	.img-disappear{
		animation-duration: 0.4s;
		animation-name: img-disappear;
	}
	@keyframes img-disappear {
		0% {
			opacity: 1;
			transform: scale3d(1, 1, 1);
		}
		100% {
			opacity: 0;
			transform: scale3d(1.1, 1.1, 1.1);
		}
		
	}
</style>